import React from "react";
import useCountdownLocalTimer from "../../hooks/useCountdownLocalTimer";
import LargeMainButton from "./LargeMainButton";

const CountdownButton = ({
  Button = LargeMainButton,
  seconds = 5,
  onClick,
  showSpinner,
  children,
  ...rest
}) => {
  const secondsLeft = useCountdownLocalTimer(seconds, onClick);

  return (
    <Button onClick={onClick} showSpinner={showSpinner} {...rest}>
      {children} {!showSpinner && <>({secondsLeft})</>}
    </Button>
  );
};

export default CountdownButton;
