import React from "react";
import CrowdOfSelfies from "./CrowdOfSelfies";
import VideoBroadcast from "./VideoBroadcast";
import ResetEventModal from "../../../../modals/ResetEventModal";
import ActionBar from "../../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../../components/buttons/LargeDarkButton";
import LargeDangerButton from "../../../../components/buttons/LargeDangerButton";
import InviteModal from "../../../../modals/InviteModal";
import SplitIntoQuizzesModal from "../../../../modals/SplitIntoQuizzesModal";
import GridGameScreenLayout from "../../../../components/layouts/GridGameScreenLayout";
import BackgroundAudio from "../../../../components/av/BackgroundAudio";
import inDevelopment from "../../../../util/inDevelopment";

export const CROWD_OF_SELFIES = "crowd-of-selfies";
export const VIDEO_BROADCAST = "video-broadcast";

const LobbyScreen = ({
  state,
  navigation,
  chat,
  audio,
  video,
  broadcasting,
  eventTitle,
  publicEventId,
  eventGroups,
  participantCount,
  selfies,
  isEventHost,
  isEventGroupHost,
  numOfEventGroups,
  onShowSplitIntoQuizzesModal,
  showSplitIntoQuizzesModal,
  onSplitIntoQuizzes,
  onCancelSplitIntoQuizzes,
  onShowInviteModal,
  showInviteModal,
  onCloseInviteModal,
  onShowResetEventModal,
  showResetEventModal,
  onReset,
  onCancelReset,
  modal,
}) => {
  let payload = null;
  switch (state) {
    case CROWD_OF_SELFIES:
      payload = (
        <CrowdOfSelfies
          eventTitle={eventTitle}
          eventGroups={eventGroups}
          participantCount={participantCount}
          selfies={selfies}
          isEventHost={isEventHost}
          isEventGroupHost={isEventGroupHost}
        />
      );
      break;

    case VIDEO_BROADCAST:
      payload = <VideoBroadcast video={video} />;
      break;
  }

  return (
    <>
      <BackgroundAudio
        muted={inDevelopment() || (isEventHost && broadcasting)}
        volume={broadcasting ? 0.3 : 0.8}
      />
      {audio}
      <GridGameScreenLayout
        navigation={navigation}
        payload={payload}
        chat={chat}
        actionBar={
          isEventHost && (
            <ActionBar>
              <LargeDarkButton noSpinner onClick={onShowSplitIntoQuizzesModal}>
                Split Into Quizzes
              </LargeDarkButton>
              <LargeDarkButton noSpinner onClick={onShowInviteModal}>
                Invite
              </LargeDarkButton>
              <LargeDangerButton noSpinner onClick={onShowResetEventModal}>
                Reset Event
              </LargeDangerButton>
            </ActionBar>
          )
        }
      />
      {showSplitIntoQuizzesModal && (
        <SplitIntoQuizzesModal
          numOfGroups={numOfEventGroups}
          numOfPlayers={participantCount}
          onSplitIntoQuizzes={onSplitIntoQuizzes}
          onCancel={onCancelSplitIntoQuizzes}
        />
      )}
      {showInviteModal && (
        <InviteModal
          publicEventId={publicEventId}
          onClose={onCloseInviteModal}
        />
      )}
      {showResetEventModal && (
        <ResetEventModal onReset={onReset} onCancel={onCancelReset} />
      )}
      {modal}
    </>
  );
};

export default LobbyScreen;
