import React, { useContext } from "react";
import EventContext from "../../../contexts/EventContext";
import { EVENT_RESULTS, QUIZZES } from "../../../constants";
import LobbyController from "./LobbyController";
import QuizzesController from "./QuizzesController";
import EventResultsController from "./EventResultsController";
import useSendGroupHostToQuizEffect from "./useSendGroupHostToQuizEffect";
import useNavigateHome from "../../hooks/useNavigateHome";
import useEventWasResetEffect from "../../../components/Event/useEventWasResetEffect";
import useSendEveryoneToSurveyEffect from "../../../components/Event/useSendEveryoneToSurveyEffect";
import SessionDataContext from "../../../contexts/SessionDataContext";
import { LocalTracksContextProvider } from "../../contexts/LocalTracksContext";
import { EventLobbyRoomContextProvider } from "../../contexts/EventLobbyRoomContext";
import HostEventQuizModal from "../../modals/HostEventQuizModal";
import JoinEventQuizModal from "../../modals/JoinEventQuizModal";
import useJoinEventQuizMutation from "../../../hooks/mutations/useJoinEventQuizMutation";
import HostingNotNeededModal from "../../modals/HostingNotNeededModal";
import useNavigateToPlayQuiz from "../../hooks/useNavigateToPlayQuiz";

const EventController = () => {
  const { myEventHostId, myEventGroupHostId, myEventPlayerId } = useContext(
    SessionDataContext
  );
  const { event, token, whatToShow, quizzes } = useContext(EventContext);
  const [joinEventQuizMutation] = useJoinEventQuizMutation();

  const {
    quizHostNotNeeded,
    success: hostingEventQuiz,
    quizName,
  } = useSendGroupHostToQuizEffect();
  useSendEveryoneToSurveyEffect();
  useEventWasResetEffect();

  const navigateHome = useNavigateHome();
  const navigateToPlayQuiz = useNavigateToPlayQuiz();

  const eventId = event && event.id;

  const handleHostEventQuiz = async () => {
    await navigateToPlayQuiz();
  };

  const handleJoinEventQuiz = async () => {
    await joinEventQuizMutation();
    await navigateToPlayQuiz();
  };

  const renderController = () => {
    if (!event) {
      return null;
    }

    if (!whatToShow) {
      return <LobbyController />;
    }

    if (whatToShow === QUIZZES && myEventGroupHostId) {
      return (
        <LobbyController
          modal={
            <>
              {quizHostNotNeeded && (
                <HostingNotNeededModal onOK={navigateHome} />
              )}
              {hostingEventQuiz && (
                <HostEventQuizModal
                  quizName={quizName}
                  onHost={handleHostEventQuiz}
                />
              )}
            </>
          }
        />
      );
    }

    if (whatToShow === QUIZZES && myEventPlayerId) {
      return (
        <LobbyController
          modal={
            <JoinEventQuizModal
              numQuizzes={quizzes && quizzes.length}
              onJoin={handleJoinEventQuiz}
            />
          }
        />
      );
    }

    if (whatToShow === QUIZZES && myEventHostId) {
      return <QuizzesController />;
    }

    if (whatToShow === EVENT_RESULTS) {
      return <EventResultsController />;
    }

    return null;
  };

  if (myEventHostId) {
    return (
      <LocalTracksContextProvider>
        <EventLobbyRoomContextProvider roomName={eventId} token={token}>
          {renderController()}
        </EventLobbyRoomContextProvider>
      </LocalTracksContextProvider>
    );
  }

  return renderController();
};

export default EventController;
