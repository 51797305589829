import React, { useEffect, useState } from "react";
import getLogger from "../util/getLogger";
import inTesting from "../util/inTesting";
import Pusher from "pusher-js";
import getPusherAppKey from "../util/getPusherAppKey";
import { SelfieCacheContextProvider } from "./SelfieCacheContext";
import { EVENT_RESULTS } from "../constants";
import parsePusherData from "../util/parsePusherData";
import useThreadProcessor from "../hooks/useThreadProcessor";
import useRefreshEventTokenMutation from "../hooks/mutations/useRefreshEventTokenMutation";
import { QUIZ_CONVERSATION } from "../v2/constants";
import { gql, useQuery } from "@apollo/client";

const logger = getLogger("EventContext");

const GET_CURRENT_EVENT = gql`
  query GetCurrentEvent {
    event: getCurrentEvent {
      id
      name
      publicId
      whatToShow
      broadcastingAudio
      broadcastingVideo
      quizzesCloseAt
      ended
      eventHosts {
        id
        name
      }
      eventGroupHosts {
        id
        name
      }
      eventPlayers {
        id
        name
      }
      eventGroups {
        id
        number
        name
        hostUser {
          username
        }
        eventGroupHost {
          id
          name
        }
        quiz {
          id
          name
          publicId
          started
          whatToShow
          roundNumber
          questionNumber
          teams {
            id
            name
            emoji
            score
          }
        }
        eventPlayers {
          id
          name
        }
      }
      chat {
        id
      }
    }
  }
`;

const EventContext = React.createContext();

export const EventContextProvider = (props) => {
  const { data: eventData } = useQuery(GET_CURRENT_EVENT);
  const [refreshEventTokenMutation] = useRefreshEventTokenMutation();

  const [event, setEvent] = useState(null);
  const [token, setToken] = useState(null);

  const [eventWasReset, setEventWasReset] = useState(false);

  const publicEventId = event && event.publicId;
  const name = event && event.name;
  const eventHosts = event && event.eventHosts;
  const eventGroupHosts = event && event.eventGroupHosts;
  const eventGroups = event ? event.eventGroups : [];
  const eventPlayers = event && event.eventPlayers;
  const readyEventPlayers =
    eventPlayers && eventPlayers.filter((player) => player.selfie);
  const whatToShow = event && event.whatToShow;
  const broadcastingAudio = event && event.broadcastingAudio;
  const broadcastingVideo = event && event.broadcastingVideo;
  const quizzesCloseAt = event && event.quizzesCloseAt;
  const chatId = event && event.chat && event.chat.id;
  const ended = event && event.ended;

  const quizzes =
    eventGroups &&
    eventGroups
      .filter((eventGroup) => eventGroup.quiz)
      .map((eventGroup) => eventGroup.quiz);

  const completedQuizzes =
    quizzes && quizzes.filter((quiz) => quiz.whatToShow === QUIZ_CONVERSATION);

  const allQuizzesCompleted = quizzes.length === completedQuizzes.length;

  const teams =
    whatToShow === EVENT_RESULTS &&
    eventGroups &&
    eventGroups
      .map((eventGroup) => (eventGroup.quiz ? eventGroup.quiz.teams : []))
      .flat(1)
      .sort((a, b) => b.score - a.score);

  async function refreshEventToken() {
    const tokenData = await refreshEventTokenMutation();
    if (tokenData) {
      setToken(tokenData.data.refreshEventToken.token);
    }
  }

  useEffect(() => {
    if (eventData) {
      setEvent(eventData.event);
    }
  }, [eventData]);

  useEffect(() => {
    refreshEventToken();
  }, []);

  const handleUpdate = (update) => {
    switch (update.update) {
      // case "eventPlayerSignedIn":
      //   setEventPlayers((currentEventPlayers) => [
      //     ...currentEventPlayers,
      //     update.data.eventPlayer,
      //   ]);
      //   break;
      //
      case "eventStreamStarted":
        logger.debug("eventStreamStarted");
        refreshEventToken();
        break;

      case "eventMutated":
        logger.debug("newData", update.data.event);
        setEvent(update.data.event);
        break;

      case "selfieSaved":
        logger.debug("skipping selfieSaved update");
        break;

      default:
        logger.error("unknown update type", update.update);
    }
  };

  const { addMessage } = useThreadProcessor(handleUpdate);

  useEffect(() => {
    if (inTesting()) {
      logger.debug(
        "We are running in test mode, so skipping any connection attempts to pusher"
      );
      return;
    }

    if (publicEventId) {
      const pusher = new Pusher(getPusherAppKey(), {
        cluster: "us3",
      });

      const channel = pusher.subscribe(publicEventId);

      logger.info("subscribed to pusher channel", publicEventId);

      channel.bind_global(async (eventName, data) => {
        logger.info(`received pusher event: ${eventName} with data`, data);
        logger.debug(data);
        if (eventName === "thread") {
          addMessage(data);
        } else if (eventName === "message") {
          handleUpdate(parsePusherData(data));
        } else if (eventName === "reset") {
          logger.info("Reset received");
          setEventWasReset(true);
        }
      });

      return () => {
        channel.unbind();
        pusher.disconnect();
      };
    }
  }, [publicEventId]);

  return (
    <EventContext.Provider
      value={{
        data: eventData,
        event,
        name,
        publicEventId,
        eventGroups,
        eventHosts,
        eventGroupHosts,
        eventPlayers,
        readyEventPlayers,
        chatId,
        token,
        refreshEventToken,
        whatToShow,
        broadcastingAudio,
        broadcastingVideo,
        allQuizzesCompleted,
        quizzesCloseAt,
        quizzes,
        teams,
        ended,
        eventWasReset,
      }}
    >
      <SelfieCacheContextProvider publicEventId={publicEventId}>
        {props.children}
      </SelfieCacheContextProvider>
    </EventContext.Provider>
  );
};

export default EventContext;
