import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeDangerButton from "../components/buttons/LargeDangerButton";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";

const CloseAllQuizzesModal = ({ onClose, onCancel }) => {
  return (
    <Modal
      title="Close All Quizzes"
      text="Are you sure you want to close all quizzes on this event?"
      buttons={
        <ButtonRow>
          <LargeDangerButton
            data-cy="close-all-quizzes-button"
            onClick={onClose}
          >
            Close
          </LargeDangerButton>
          <LargeOutlineButton onClick={onCancel}>Cancel</LargeOutlineButton>
        </ButtonRow>
      }
      onCancel={onCancel}
    />
  );
};

export default CloseAllQuizzesModal;
