import React from "react";
import styled from "styled-components";
import { WHITE } from "../../../../colors";
import ActionBar from "../../../../components/actionBar/ActionBar";
import LargeDangerButton from "../../../../components/buttons/LargeDangerButton";
import { TITLE_HEADER } from "../../../../typography";
import Counter from "../../../../components/elements/paging/Counter";
import ResetEventModal from "../../../../modals/ResetEventModal";
import CloseAllQuizzesModal from "../../../../modals/CloseAllQuizzesModal";
import HostingNotNeededModal from "../../../../modals/HostingNotNeededModal";
import GridGameScreenLayout from "../../../../components/layouts/GridGameScreenLayout";
import GridVerticalScrollLayout from "../../../../components/layouts/GridVerticalScrollLayout";
import getTimeLeftText from "../../../../util/getTimeLeftText";
import Progress from "../../../../components/progress/Progress";

const CLOSE_QUIZZES_TIME = 30;

const ProgressWrapper = styled.div`
  margin-bottom: 20px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  ${TITLE_HEADER}
  color: ${WHITE};
  margin-right: 6px;
`;

const QuizzesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const QuizWrapper = styled.div`
  margin: 10px;
`;

const QuizzesScreen = ({
  navigation,
  chat,
  eventTitle,
  participantCount,
  quizzes,
  allQuizzesCompleted,
  timeLeftBeforeQuizzesClose,
  isEventHost,
  quizHostNotNeeded,
  onQuizHostNotNeededAck,
  onShowCloseAllQuizzesModal,
  showCloseAllQuizzesModal,
  onCloseAllQuizzes,
  onCancelCloseAllQuizzes,
  onShowResetEventModal,
  showResetEventModal,
  onReset,
  onCancelReset,
}) => {
  const closeQuizzesProgress =
    ((CLOSE_QUIZZES_TIME + 1 - timeLeftBeforeQuizzesClose) /
      CLOSE_QUIZZES_TIME) *
    100;

  let closeQuizzesRightMessage = getTimeLeftText(timeLeftBeforeQuizzesClose);
  if (timeLeftBeforeQuizzesClose <= 0) {
    closeQuizzesRightMessage = "Quizzes closed";
  }

  return (
    <>
      <GridGameScreenLayout
        navigation={navigation}
        chat={chat}
        payload={
          isEventHost && (
            <GridVerticalScrollLayout
              header={
                <>
                  {allQuizzesCompleted && (
                    <ProgressWrapper>
                      <Progress
                        leftMessage={"Time before quizzes close"}
                        progress={closeQuizzesProgress}
                        rightMessage={
                          ![null, undefined].includes(
                            timeLeftBeforeQuizzesClose
                          ) && closeQuizzesRightMessage
                        }
                      />
                    </ProgressWrapper>
                  )}

                  <Heading>
                    <Title>{eventTitle}</Title>
                    <Counter>{participantCount}</Counter>
                  </Heading>
                </>
              }
              content={
                <QuizzesWrapper>
                  {quizzes.map((quiz) => (
                    <QuizWrapper key={quiz.id}>{quiz}</QuizWrapper>
                  ))}
                </QuizzesWrapper>
              }
            />
          )
        }
        actionBar={
          isEventHost && (
            <ActionBar>
              <LargeDangerButton noSpinner onClick={onShowCloseAllQuizzesModal}>
                Close All Quizzes
              </LargeDangerButton>
              <LargeDangerButton noSpinner onClick={onShowResetEventModal}>
                Reset Event
              </LargeDangerButton>
            </ActionBar>
          )
        }
      />
      {quizHostNotNeeded && (
        <HostingNotNeededModal onOK={onQuizHostNotNeededAck} />
      )}
      {showCloseAllQuizzesModal && (
        <CloseAllQuizzesModal
          onClose={onCloseAllQuizzes}
          onCancel={onCancelCloseAllQuizzes}
        />
      )}
      {showResetEventModal && (
        <ResetEventModal onReset={onReset} onCancel={onCancelReset} />
      )}
    </>
  );
};

export default QuizzesScreen;
