import React from "react";
import styled from "styled-components";

const PayloadWrapper = styled.div`
  height: 100%;
`;

const VideoBroadcast = ({ video }) => {
  return <PayloadWrapper hide={false}>{video}</PayloadWrapper>;
};

export default VideoBroadcast;
