import React, { useContext } from "react";
import EventContext from "../../contexts/EventContext";
import useLivePlayer from "../hooks/av/useLivePlayer";
import useLivePlayerState from "../hooks/av/useLivePlayerState";

const EventLivePlayerContext = React.createContext();

export const EventLivePlayerContextProvider = ({ children }) => {
  const { token, refreshEventToken } = useContext(EventContext);

  const [livePlayer] = useLivePlayer(token, refreshEventToken);
  const livePlayerState = useLivePlayerState(livePlayer);

  return (
    <EventLivePlayerContext.Provider value={{ livePlayer, livePlayerState }}>
      {children}
    </EventLivePlayerContext.Provider>
  );
};

export default EventLivePlayerContext;
