import React from "react";
import EventGroup from "../../../components/events/EventGroup";
import EventSelfieController from "../EventSelfieController";

const LobbyEventGroupController = ({ eventGroup }) => {
  const hostUser = eventGroup && eventGroup.hostUser;
  const hostName = hostUser && hostUser.username;
  const eventGroupHost = eventGroup && eventGroup.eventGroupHost;

  return (
    <EventGroup
      number={eventGroup.number}
      name={eventGroup.name}
      hostName={hostName}
      selfie={
        eventGroupHost && <EventSelfieController id={eventGroupHost.id} />
      }
    />
  );
};

export default LobbyEventGroupController;
