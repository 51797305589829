import React from "react";
import styled from "styled-components";
import { WHITE } from "../../../../colors";
import GradientCard from "../../../../components/elements/cards/GradientCard";
import { PARAGRAPH_NORMAL, TITLE_HEADER } from "../../../../typography";
import Counter from "../../../../components/elements/paging/Counter";
import CrowdLayout from "../../../../components/layouts/CrowdLayout";
import GridVerticalScrollLayout from "../../../../components/layouts/GridVerticalScrollLayout";

const PayloadWrapper = styled.div`
  height: 100%;
`;

const StyledGradientCard = styled(GradientCard)`
  padding: 40px 50px;
  box-sizing: border-box;
  height: 100%;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  ${TITLE_HEADER}
  color: ${WHITE};
  margin-right: 6px;
`;

const EventGroupsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const EventGroupWrapper = styled.div`
  margin: 0 10px;
`;

const FriendlyMessage = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  margin-bottom: 50px;
`;

const CrowdWrapper = styled.div``;

const CrowdOfSelfies = ({
  hide,
  eventTitle,
  eventGroups,
  participantCount,
  selfies,
  isEventHost,
  isEventGroupHost,
}) => {
  return (
    <PayloadWrapper hide={hide}>
      <StyledGradientCard>
        <GridVerticalScrollLayout
          header={
            <>
              <Heading>
                <Title>{eventTitle}</Title>
                <Counter>{participantCount}</Counter>
              </Heading>
              {(isEventHost || isEventGroupHost) && (
                <EventGroupsWrapper>
                  {eventGroups &&
                    eventGroups.map((eventGroup) => (
                      <EventGroupWrapper key={eventGroup.key}>
                        {eventGroup}
                      </EventGroupWrapper>
                    ))}
                </EventGroupsWrapper>
              )}
              {!isEventHost && !isEventGroupHost && (
                <FriendlyMessage>
                  We will be starting soon. In the meantime, have fun chatting
                  with your fellow players!
                </FriendlyMessage>
              )}
            </>
          }
          content={
            <CrowdWrapper>
              <CrowdLayout individuals={selfies} />
            </CrowdWrapper>
          }
        />
      </StyledGradientCard>
    </PayloadWrapper>
  );
};

export default CrowdOfSelfies;
