import { useCallback } from "react";
import useCloseAllQuizzesMutation from "../../../hooks/mutations/useCloseAllQuizzesMutation";

const useHandleCloseAllQuizzes = () => {
  const [closeAllQuizzesMutation] = useCloseAllQuizzesMutation();

  return useCallback(async () => {
    await closeAllQuizzesMutation({});
  }, []);
};

export default useHandleCloseAllQuizzes;
