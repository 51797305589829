import React, { useContext, useState } from "react";
import EventChatContext from "../../../../contexts/EventChatContext";
import Chat from "../../../components/chat/Chat";
import ChatMessage from "../../../components/chat/ChatMessage";
import SelfieCacheContext from "../../../../contexts/SelfieCacheContext";
import ChatInput from "../../../components/chat/ChatInput";
import insertTextAtPoint from "../../../util/insertTextAtPoint";
import EmojiPicker from "../../../components/elements/emojis/EmojiPicker";
import EventSelfieController from "../EventSelfieController";

const EventChatController = () => {
  const { messages, refetchedAt, sendChatMessage } = useContext(
    EventChatContext
  );
  const { getSelfieForParticipant } = useContext(SelfieCacheContext);
  const [chatInputValue, setChatInputValue] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectionStart, setSelectionStart] = useState(0);

  const handleSelectEmoji = (emoji) => {
    setChatInputValue((prevInputValue) =>
      insertTextAtPoint(prevInputValue, emoji.native, selectionStart)
    );
    setShowEmojiPicker(false);
  };

  const getParticipant = (message) => {
    const eventHost = message.fromEventHost;
    const eventGroupHost = message.fromEventGroupHost;
    const eventPlayer = message.fromEventPlayer;
    return eventHost || eventGroupHost || eventPlayer;
  };

  const getName = (message) => {
    const participant = getParticipant(message);
    return participant && participant.name;
  };

  const getIsHost = (message) =>
    Boolean(message.fromEventHost || message.fromEventGroupHost);

  const getSelfieSrc = (message) => {
    const participant = getParticipant(message);
    return getSelfieForParticipant(participant.id);
  };

  const getTeam = (message) =>
    message.fromEventPlayer &&
    message.fromEventPlayer.player &&
    message.fromEventPlayer.player.team;

  const getTeamName = (message) => {
    const team = getTeam(message);
    return team && team.name;
  };

  const getEmoji = (message) => {
    const team = getTeam(message);
    return team && team.emoji;
  };

  return (
    <Chat
      chatMessages={
        messages &&
        messages.map((message) => (
          <ChatMessage
            key={message.id}
            host={getIsHost(message)}
            name={getName(message)}
            teamName={getTeamName(message)}
            emoji={getEmoji(message)}
            selfie={
              <EventSelfieController
                id={getParticipant(message).id}
                type={"chat"}
              />
            }
            selfieSrc={getSelfieSrc(message)}
            text={message.body}
          />
        ))
      }
      refetchedAt={refetchedAt}
      chatInput={
        <ChatInput
          value={chatInputValue}
          onEmojiPickerClick={() => setShowEmojiPicker(true)}
          emojiPickerOpen={showEmojiPicker}
          onSelectionStartChange={setSelectionStart}
          onChange={setChatInputValue}
          onSend={sendChatMessage}
        />
      }
      emojiPicker={
        showEmojiPicker && (
          <EmojiPicker
            onSelect={handleSelectEmoji}
            onClose={() => setShowEmojiPicker(false)}
          />
        )
      }
    />
  );
};

export default EventChatController;
