import { useContext, useEffect } from "react";
import EventContext from "../../contexts/EventContext";
import { navigate } from "gatsby";

const useEventWasResetEffect = () => {
  const { eventWasReset } = useContext(EventContext);

  useEffect(() => {
    if (eventWasReset) {
      navigate("/");
    }
  }, [eventWasReset]);
};

export default useEventWasResetEffect;
