import React from "react";
import styled from "styled-components";
import { ELEMENT_DEFAULT, WHITE } from "../../colors";
import { PARAGRAPH_LARGE_BOLD } from "../../typography";
import TippyTooltip from "../elements/tooltips/TippyTooltip";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: ${ELEMENT_DEFAULT};
  color: ${WHITE};
  ${PARAGRAPH_LARGE_BOLD}
  cursor: default;
  position: relative;
`;

const SelfieWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const EventGroup = ({ number, name, hostName, selfie }) => {
  return (
    <TippyTooltip content={`${name} - ${hostName}`}>
      <Wrapper>
        {number}
        {selfie && <SelfieWrapper>{selfie}</SelfieWrapper>}
      </Wrapper>
    </TippyTooltip>
  );
};

export default EventGroup;
