import React, { useContext, useEffect, useState } from "react";
import EventLivePlayerContext from "../../../contexts/EventLivePlayerContext";
import GalleryLayout from "../../../components/layouts/GalleryLayout";
import Video from "../../../components/av/Video";
import useCallbackRef from "../../../hooks/useCallbackRef";
import getLogger from "../../../../util/getLogger";
import useAttachLivePlayerToVideo from "../../../hooks/av/useAttachLivePlayerToVideo";

const logger = getLogger("EventVideoBroadcastController");

const EventVideoBroadcastController = () => {
  const { livePlayer, livePlayerState } = useContext(EventLivePlayerContext);
  const [videoEl, setVideoEl] = useState();
  const videoRef = useCallbackRef(setVideoEl);

  useAttachLivePlayerToVideo(livePlayer, videoEl);

  useEffect(() => {
    const { Player } = require("@twilio/live-player-sdk");

    logger.debug("got a state update", livePlayerState);
    if (livePlayerState === Player.State.Ready) {
      livePlayer.play();
      livePlayer.setVolume(0.7);
    }
  }, [livePlayerState]);

  return (
    <GalleryLayout
      participants={[<Video key={1} videoRef={videoRef} />]}
      aspectRatio={16 / 9}
    />
  );
};

export default EventVideoBroadcastController;
