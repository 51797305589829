import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import CountdownButton from "../components/buttons/CountdownButton";

const JoinEventQuizModal = ({ numQuizzes, onJoin }) => {
  return (
    <Modal
      title="Join Your Quiz"
      text={`This event will have ${numQuizzes} different quizzes to keep things fun and interactive.`}
      buttons={
        <ButtonRow>
          <CountdownButton seconds={5} onClick={onJoin}>
            Join
          </CountdownButton>
        </ButtonRow>
      }
    />
  );
};

export default JoinEventQuizModal;
