import React from "react";
import styled from "styled-components";
import { TITLE_HEADER } from "../../../../typography";
import { WHITE } from "../../../../colors";
import Leaderboard from "../../../../components/leaderboard/Leaderboard";
import GridVerticalScrollLayout from "../../../../components/layouts/GridVerticalScrollLayout";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  ${TITLE_HEADER}
  color: ${WHITE};
  margin-right: 6px;
`;

const EventLeaderboard = ({ eventTitle, teams }) => {
  return (
    <Wrapper>
      <GridVerticalScrollLayout
        header={
          <Heading>
            <Title>{eventTitle}: Final Results</Title>
          </Heading>
        }
        content={<Leaderboard teams={teams} />}
      />
    </Wrapper>
  );
};

export default EventLeaderboard;
