import { useCallback } from "react";
import {
  ANSWERS,
  FACTS,
  MEET_TEAMS,
  NAME_TEAMS,
  QUESTION,
  QUESTION_RESULTS,
  QUIZ_CONVERSATION,
  QUIZ_INTRO,
  QUIZ_RESULTS,
  ROUND_INTRO,
  ROUND_RESULTS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../../../constants";

const useGetQuizStatus = () => {
  return useCallback((quiz) => {
    if (!quiz.started) {
      return "Waiting Room";
    } else if ([QUIZ_INTRO].includes(quiz.whatToShow)) {
      return "Quiz Intro";
    } else if ([NAME_TEAMS].includes(quiz.whatToShow)) {
      return "Naming Teams";
    } else if ([MEET_TEAMS].includes(quiz.whatToShow)) {
      return "Meeting Teams";
    } else if ([ROUND_INTRO].includes(quiz.whatToShow)) {
      return `Round ${quiz.roundNumber} Intro`;
    } else if (
      [
        QUESTION,
        ANSWERS,
        QUESTION_RESULTS,
        FACTS,
        ROUND_RESULTS,
        QUIZ_RESULTS,
      ].includes(quiz.whatToShow)
    ) {
      return `Round ${quiz.roundNumber}, Question ${quiz.questionNumber}`;
    } else if (
      [TIEBREAKER, TIEBREAKER_ANSWERS, TIEBREAKER_RESULTS].includes(
        quiz.whatToShow
      )
    ) {
      return "Tiebreaker";
    } else if ([QUIZ_CONVERSATION].includes(quiz.whatToShow)) {
      return "Completed";
    }
  }, []);
};

export default useGetQuizStatus;
