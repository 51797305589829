import React from "react";
import styled from "styled-components";
import { PARAGRAPH_LARGE_BOLD, PARAGRAPH_NORMAL } from "../../typography";
import { WHITE } from "../../colors";
import PrimaryCard from "../elements/cards/PrimaryCard";
import CrowdLayout from "../layouts/CrowdLayout";

const StyledPrimaryCard = styled(PrimaryCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 225px;
  padding: 20px 40px 50px;
`;

const QuizName = styled.div`
  ${PARAGRAPH_LARGE_BOLD}
  color: ${WHITE};
  margin-bottom: 10px;
`;

const HostName = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  margin-bottom: 5px;
`;

const WhatToShow = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  margin-bottom: 15px;
`;

const CrowdWrapper = styled.div``;

const EventQuiz = ({ quizName, hostName, status, selfies }) => {
  return (
    <StyledPrimaryCard>
      <QuizName>{quizName}</QuizName>
      <HostName>Host: {hostName}</HostName>
      <WhatToShow>{status}</WhatToShow>
      <CrowdWrapper>
        <CrowdLayout individuals={selfies} />
      </CrowdWrapper>
    </StyledPrimaryCard>
  );
};

export default EventQuiz;
