import { useEffect } from "react";
import getLogger from "../../../util/getLogger";

const logger = getLogger("useAttachLivePlayerToVideo");

const useAttachLivePlayerToVideo = (livePlayer, el) => {
  useEffect(() => {
    logger.info("Attaching livePlayer to video");
    logger.debug("livePlayer", livePlayer);
    logger.debug("el", el);

    if (livePlayer && el) {
      livePlayer.attach(el);
      // livePlayer.attachHTMLVideoElement(el);

      return () => {
        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;
      };
    }
  }, [livePlayer, el]);
};

export default useAttachLivePlayerToVideo;
