import { gql, useMutation } from "@apollo/client";

const HOST_EVENT_QUIZ = gql`
  mutation HostEventQuiz {
    hostEventQuiz {
      quiz {
        id
        name
        publicId
      }
    }
  }
`;

const useHostEventQuizMutation = () => useMutation(HOST_EVENT_QUIZ);

export default useHostEventQuizMutation;
