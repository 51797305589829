import React, { useEffect, useRef } from "react";
import Audio from "./Audio";
import inTesting from "../../../util/inTesting";

const BackgroundAudio = ({ volume = 1.0, muted }) => {
  const audioRef = useRef();

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume;
    }
  }, [audioRef, volume]);

  return (
    <Audio audioRef={audioRef} loop muted={muted}>
      {!inTesting() && (
        <source
          src={"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"}
        />
      )}
    </Audio>
  );
};

export default BackgroundAudio;
