import React, { createContext, useContext, useEffect, useState } from "react";
import useRoom from "../hooks/av/useRoom";
import LocalTracksContext from "./LocalTracksContext";
import SessionDataContext from "../../contexts/SessionDataContext";

const EventLobbyRoomContext = createContext();

export const EventLobbyRoomContextProvider = ({
  roomName,
  token,
  children,
}) => {
  const { myEventHostId } = useContext(SessionDataContext);

  const [muted, setMuted] = useState(false);

  const {
    localAudioTrack,
    localVideoTrack,
    localVideoEnabled,
    disableLocalVideo,
    enableLocalVideo,
  } = useContext(LocalTracksContext);

  const { room, isConnecting, connect } = useRoom(
    roomName,
    token,
    localVideoTrack,
    localAudioTrack
  );

  const localParticipant = room && room.localParticipant;

  const handleMute = (value) => {
    if (localParticipant) {
      if (value) {
        localParticipant.unpublishTrack(localAudioTrack);
        setMuted(true);
      } else {
        localParticipant.publishTrack(localAudioTrack);
        setMuted(false);
      }
    }
  };

  // host begins muted and camera off
  useEffect(() => {
    if (
      localAudioTrack &&
      localVideoTrack &&
      myEventHostId &&
      localParticipant
    ) {
      handleMute(true);
      disableLocalVideo();
    }
  }, [localAudioTrack, localVideoTrack, myEventHostId, localParticipant]);

  return (
    <EventLobbyRoomContext.Provider
      value={{
        roomName,
        room,
        isConnecting,
        connect,
        localAudioTrack,
        localVideoTrack,
        localVideoEnabled,
        enableLocalVideo,
        disableLocalVideo,
        muted,
        setMuted: handleMute,
        videoStopped: !localVideoEnabled,
        setVideoStopped: (value) =>
          value ? disableLocalVideo() : enableLocalVideo(),
      }}
    >
      {children}
    </EventLobbyRoomContext.Provider>
  );
};

export default EventLobbyRoomContext;
