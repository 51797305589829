import React, { useContext, useEffect, useState } from "react";
import getLogger from "../util/getLogger";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import inTesting from "../util/inTesting";
import Pusher from "pusher-js";
import getPusherAppKey from "../util/getPusherAppKey";
import EventContext from "./EventContext";
import getTimeStamp from "../util/getTimeStamp";
import parsePusherData from "../util/parsePusherData";
import useThreadProcessor from "../hooks/useThreadProcessor";

const logger = getLogger("EventChatContext");

const GET_CURRENT_EVENT_CHAT = gql`
  query GetCurrentEventChat {
    chat: getCurrentEventChat {
      id
      messages {
        id
        body
        fromPlayer {
          id
          name
          team {
            id
            name
            emoji
          }
        }
        fromHost {
          id
          name
        }
        fromEventPlayer {
          id
          name
          player {
            id
            team {
              id
              name
              emoji
            }
          }
        }
        fromEventHost {
          id
          name
        }
        fromEventGroupHost {
          id
          name
        }
      }
    }
  }
`;

const SEND_CHAT_MESSAGE = gql`
  mutation SendChatMessage($chat: String!, $body: String!, $version: Int) {
    sendChatMessage(chat: $chat, body: $body, version: $version) {
      ok
    }
  }
`;

const EventChatContext = React.createContext();

export const EventChatContextProvider = (props) => {
  const { chatId } = useContext(EventContext);
  const [getCurrentEventChat, { data }] = useLazyQuery(GET_CURRENT_EVENT_CHAT);

  const [chat, setChat] = useState(null);
  const [refetchedAt, setRefetchedAt] = useState(null);

  const [sendChatMessageMutation] = useMutation(SEND_CHAT_MESSAGE);

  const handleChatUpdate = async (update) => {
    switch (update.update) {
      case "chatMutated":
        setChat(update.data.chat);
        setRefetchedAt(getTimeStamp());
    }
  };

  const { addMessage } = useThreadProcessor(handleChatUpdate);

  useEffect(() => {
    if (data) {
      setChat(data.chat);
    }
  }, [data]);

  useEffect(() => {
    if (inTesting()) {
      logger.debug(
        "We are running in test mode, so skipping any connection attempts to pusher"
      );
      return;
    }

    if (chatId) {
      const pusher = new Pusher(getPusherAppKey(), {
        cluster: "us3",
      });

      const channel = pusher.subscribe(`${chatId}`);

      channel.bind_global(async (eventName, data) => {
        logger.info(`received pusher event: ${eventName} with data`, data);
        if (eventName === "thread") {
          addMessage(data);
        } else if (eventName === "message") {
          await handleChatUpdate(parsePusherData(data));
        }
      });

      getCurrentEventChat();

      return () => {
        channel.unbind();
        pusher.disconnect();
      };
    }
  }, [chatId]);

  const sendChatMessage = async (body) => {
    logger.info("Send Chat Message");
    await sendChatMessageMutation({
      variables: {
        chat: "event",
        body,
        version: 2,
      },
    });
  };

  const messages = chat && chat.messages;

  return (
    <EventChatContext.Provider
      value={{
        chat,
        messages,
        sendChatMessage,
        refetchedAt,
      }}
    >
      {props.children}
    </EventChatContext.Provider>
  );
};

export default EventChatContext;
