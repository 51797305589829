import React from "react";
import VideoBroadcast from "../../01/LobbyScreen/VideoBroadcast";
import EventLeaderboard from "./EventLeaderboard";
import ActionBar from "../../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../../components/buttons/LargeDarkButton";
import LargeDangerButton from "../../../../components/buttons/LargeDangerButton";
import GridGameScreenLayout from "../../../../components/layouts/GridGameScreenLayout";
import ResetEventModal from "../../../../modals/ResetEventModal";
import styled from "styled-components";

export const LEADERBOARD = "leaderboard";
export const VIDEO_BROADCAST = "video-broadcast";

const CelebrationWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 15;
`;

const EventResultsScreen = ({
  state,
  navigation,
  chat,
  video,
  eventTitle,
  teams,
  celebration,
  isEventHost,
  onEndEvent,
  onShowResetEventModal,
  showResetEventModal,
  onReset,
  onCancelReset,
}) => {
  let payload = null;
  switch (state) {
    case LEADERBOARD:
      payload = <EventLeaderboard eventTitle={eventTitle} teams={teams} />;
      break;

    case VIDEO_BROADCAST:
      payload = <VideoBroadcast video={video} />;
  }

  return (
    <>
      <GridGameScreenLayout
        navigation={navigation}
        payload={payload}
        chat={chat}
        actionBar={
          isEventHost && (
            <ActionBar>
              <LargeDarkButton onClick={onEndEvent}>End Event</LargeDarkButton>
              <LargeDangerButton noSpinner onClick={onShowResetEventModal}>
                Reset Event
              </LargeDangerButton>
            </ActionBar>
          )
        }
      />
      {celebration && <CelebrationWrapper>{celebration}</CelebrationWrapper>}
      {showResetEventModal && (
        <ResetEventModal onReset={onReset} onCancel={onCancelReset} />
      )}
    </>
  );
};

export default EventResultsScreen;
