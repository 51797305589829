import { useEffect, useState } from "react";
import getLogger from "../../../util/getLogger";

const logger = getLogger("useLivePlayerState");

const useLivePlayerState = (livePlayer) => {
  const [state, setState] = useState();

  useEffect(() => {
    const { Player } = require("@twilio/live-player-sdk");

    const handleStateChange = (state) => {
      logger.debug("state change", state);
      setState(state);
    };

    if (livePlayer) {
      livePlayer.on(Player.Event.StateChanged, handleStateChange);

      return () => {
        livePlayer.off(Player.Event.StateChanged, handleStateChange);
      };
    }
  }, [livePlayer]);

  return state;
};

export default useLivePlayerState;
