import { useCallback, useEffect, useState } from "react";
import getLogger from "../../../util/getLogger";
import useLivePlayerState from "./useLivePlayerState";

const logger = getLogger("useLivePlayer");

const useLivePlayer = (token, refreshToken) => {
  const [livePlayer, setLivePlayer] = useState();
  const state = useLivePlayerState(livePlayer);

  const connect = useCallback(async () => {
    const { Player } = require("@twilio/live-player-sdk");

    logger.info("Connecting to stream using token", token);

    try {
      const newLivePlayer = await Player.connect(token, {
        playerWasmAssetsPath: "/static",
      });
      setLivePlayer(newLivePlayer);
      logger.info("connected");
    } catch (err) {
      logger.error("error connecting", err);
      refreshToken();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      logger.debug("auto-connecting");
      connect();
    }
  }, [token]);

  useEffect(() => {
    logger.debug("livePlayer is now set to", livePlayer);
    if (livePlayer) {
      return () => {
        logger.info("disconnecting");
        livePlayer.disconnect();
      };
    }
  }, [livePlayer]);

  useEffect(() => {
    if (state === "ended") {
      logger.debug("refreshing token");
      refreshToken();
    }
  }, [state]);

  return [livePlayer, connect];
};

export default useLivePlayer;
