import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeMainButton from "../components/buttons/LargeMainButton";

const HostingNotNeededModal = ({ onOK }) => {
  return (
    <Modal
      title="Hosting Not Needed"
      text="Event host reduced the number of quizzes to match the turnout. You are not needed as a host for this event. But don’t worry, you’ll still get paid! 😀"
      buttons={
        <ButtonRow>
          <LargeMainButton onClick={onOK}>OK</LargeMainButton>
        </ButtonRow>
      }
    />
  );
};

export default HostingNotNeededModal;
