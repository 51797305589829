import { gql, useMutation } from "@apollo/client";

const CLOSE_ALL_QUIZZES = gql`
  mutation CloseAllEventQuizzes {
    closeAllEventQuizzes {
      event {
        id
      }
    }
  }
`;

const useCloseAllQuizzesMutation = () => useMutation(CLOSE_ALL_QUIZZES);

export default useCloseAllQuizzesMutation;
