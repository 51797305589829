import React, { useContext, useState } from "react";
import LobbyScreen, {
  CROWD_OF_SELFIES,
  VIDEO_BROADCAST,
} from "../../../screens/event/01/LobbyScreen";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import EventContext from "../../../../contexts/EventContext";
import SelfieCacheContext from "../../../../contexts/SelfieCacheContext";
import useHandleResetEvent from "../../../hooks/useHandleResetEvent";
import useSplitIntoQuizzesMutation from "../../../hooks/mutations/useSplitIntoQuizzesMutation";
import EventChatController from "../EventChatController";
import EventNavigationController from "../EventNavigationController";
import EventLobbyRoomController from "../EventLobbyRoomController";
import EventVideoBroadcastController from "../EventVideoBroadcastController";
import EventSelfieController from "../EventSelfieController";
import { EventLivePlayerContextProvider } from "../../../contexts/EventLivePlayerContext";
import EventAudioBroadcastController from "../EventAudioBroadcastController";
import LobbyEventGroupController from "./LobbyEventGroupController";

const LobbyController = ({ modal }) => {
  const { myEventHostId, myEventGroupHostId } = useContext(SessionDataContext);
  const {
    name,
    eventGroups,
    eventPlayers,
    publicEventId,
    broadcastingAudio,
    broadcastingVideo,
  } = useContext(EventContext);
  const { getSelfieForParticipant } = useContext(SelfieCacheContext);
  const [showSplitIntoQuizzesModal, setShowSplitIntoQuizzesModal] = useState();
  const [showInviteModal, setShowInviteModal] = useState();
  const [showResetEventModal, setShowResetEventModal] = useState();
  const handleResetEvent = useHandleResetEvent(publicEventId);
  const [splitIntoQuizzesMutation] = useSplitIntoQuizzesMutation();

  const handleSplitIntoQuizzes = (numberOfQuizzes) => {
    splitIntoQuizzesMutation({ variables: { numberOfQuizzes } });
  };

  const playersWithSelfies =
    eventPlayers &&
    eventPlayers.filter((player) => getSelfieForParticipant(player.id));

  const playerCount = playersWithSelfies ? playersWithSelfies.length : 0;
  const numOfEventGroups = eventGroups ? eventGroups.length : 0;

  return (
    <LobbyScreen
      state={broadcastingVideo ? VIDEO_BROADCAST : CROWD_OF_SELFIES}
      navigation={<EventNavigationController />}
      broadcasting={broadcastingAudio || broadcastingVideo}
      audio={
        !broadcastingVideo &&
        !myEventHostId && (
          <EventLivePlayerContextProvider>
            <EventAudioBroadcastController />
          </EventLivePlayerContextProvider>
        )
      }
      video={
        broadcastingVideo &&
        (myEventHostId ? (
          <EventLobbyRoomController />
        ) : (
          <EventLivePlayerContextProvider>
            <EventVideoBroadcastController />
          </EventLivePlayerContextProvider>
        ))
      }
      chat={<EventChatController />}
      eventTitle={name}
      eventGroups={
        eventGroups &&
        eventGroups.map((eventGroup) => (
          <LobbyEventGroupController
            key={eventGroup.id}
            eventGroup={eventGroup}
          />
        ))
      }
      publicEventId={publicEventId}
      participantCount={playerCount}
      selfies={
        playersWithSelfies &&
        playersWithSelfies.map((player) => (
          <EventSelfieController
            key={player.id}
            id={player.id}
            name={player.name}
          />
        ))
      }
      isEventHost={myEventHostId}
      isEventGroupHost={myEventGroupHostId}
      numOfEventGroups={numOfEventGroups}
      onShowSplitIntoQuizzesModal={() => setShowSplitIntoQuizzesModal(true)}
      showSplitIntoQuizzesModal={showSplitIntoQuizzesModal}
      onSplitIntoQuizzes={handleSplitIntoQuizzes}
      onCancelSplitIntoQuizzes={() => setShowSplitIntoQuizzesModal(false)}
      onShowInviteModal={() => setShowInviteModal(true)}
      showInviteModal={showInviteModal}
      onCloseInviteModal={() => setShowInviteModal(false)}
      onShowResetEventModal={() => setShowResetEventModal(true)}
      showResetEventModal={showResetEventModal}
      onReset={handleResetEvent}
      onCancelReset={() => setShowResetEventModal(false)}
      modal={modal}
    />
  );
};

export default LobbyController;
