import { useCallback } from "react";
import useResetEventMutation from "./mutations/useResetEventMutation";
import { navigate } from "gatsby";

const useHandleResetEvent = (publicEventId) => {
  const [resetEventMutation] = useResetEventMutation();

  return useCallback(async () => {
    await resetEventMutation({
      variables: {
        publicEventId,
      },
    });
    await navigate("/");
  }, [publicEventId]);
};

export default useHandleResetEvent;
