import { gql, useMutation } from "@apollo/client";

const RESET_EVENT = gql`
  mutation ResetEvent($publicEventId: String!) {
    resetEvent(publicEventId: $publicEventId) {
      event {
        id
      }
    }
  }
`;

const useResetEventMutation = () => useMutation(RESET_EVENT);

export default useResetEventMutation;
