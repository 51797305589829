import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation SetBroadcastAudio($value: Boolean!) {
      setBroadcastAudio(value: $value) {
        event {
          id
        }
      }
    }
  `);
