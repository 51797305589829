import React from "react";
import Video from "./Video";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 0;
`;

const VideoWithOnlyAudio = ({
  videoRef,
  sources,
  reversed,
  muted,
  ...rest
}) => {
  return (
    <Wrapper>
      <Video
        videoRef={videoRef}
        sources={sources}
        reversed={reversed}
        muted={muted}
        {...rest}
      />
    </Wrapper>
  );
};

export default VideoWithOnlyAudio;
