import React, { useContext, useState } from "react";
import GalleryLayout from "../../../components/layouts/GalleryLayout";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import Avatar from "../../../components/avatars/Avatar";
import Video from "../../../components/av/Video";
import useAttachVideoTrackEffect from "../../../hooks/av/useAttachVideoTrackEffect";
import EventLobbyRoomContext from "../../../contexts/EventLobbyRoomContext";
import useCallbackRef from "../../../hooks/useCallbackRef";

const EventLobbyRoomController = () => {
  const { localVideoTrack } = useContext(EventLobbyRoomContext);
  const { myEventHostId, myName } = useContext(SessionDataContext);

  const [videoEl, setVideoEl] = useState();
  const videoRef = useCallbackRef(setVideoEl);

  useAttachVideoTrackEffect(localVideoTrack, videoEl);

  return (
    <GalleryLayout
      participants={[
        <Avatar key={myEventHostId} name={myName} host>
          <Video videoRef={videoRef} reversed />
        </Avatar>,
      ]}
    />
  );
};

export default EventLobbyRoomController;
