import React, { useState } from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";
import SliderInput from "../components/inputs/SliderInput";
import LargeMainButton from "../components/buttons/LargeMainButton";
import styled from "styled-components";
import { PARAGRAPH_NORMAL } from "../typography";
import { TEXT_DEFAULT } from "../colors";

const TextLine = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${TEXT_DEFAULT};
  text-align: center;
  margin-bottom: 5px;
`;

const SplitIntoQuizzesModal = ({
  numOfGroups,
  numOfPlayers,
  onSplitIntoQuizzes,
  onCancel,
}) => {
  const maxQuizzes = numOfGroups;

  const [numQuizzes, setNumQuizzes] = useState(maxQuizzes);

  const minQuizSize = Math.floor(numOfPlayers / numQuizzes);
  const maxQuizSize = Math.ceil(numOfPlayers / numQuizzes);

  const tooMany = maxQuizSize > 36;

  const handleSplitClick = () => {
    if (onSplitIntoQuizzes) {
      onSplitIntoQuizzes(numQuizzes);
    }
  };

  return (
    <Modal
      title="Split Into Quizzes"
      text={
        <>
          <TextLine>Number of quizzes: {numQuizzes}</TextLine>
          {numOfPlayers > 0 && (
            <>
              <TextLine>
                {minQuizSize}
                {maxQuizSize > minQuizSize && ` - ${maxQuizSize}`}{" "}
                {`player${minQuizSize === 1 && maxQuizSize === 1 ? "" : "s"}`} /
                quiz
              </TextLine>
            </>
          )}

          {numOfPlayers === 0 && <TextLine>0 players / quiz</TextLine>}
        </>
      }
      body={
        <SliderInput
          value={numQuizzes}
          minValue={1}
          maxValue={maxQuizzes}
          onChange={setNumQuizzes}
        />
      }
      buttons={
        <ButtonRow>
          <LargeMainButton
            data-cy="split-button"
            onClick={handleSplitClick}
            disabled={tooMany}
          >
            Split
          </LargeMainButton>
          <LargeOutlineButton noSpinner onClick={onCancel}>
            Cancel
          </LargeOutlineButton>
        </ButtonRow>
      }
      onEscapeKey={onCancel}
    />
  );
};

export default SplitIntoQuizzesModal;
