import { gql, useMutation } from "@apollo/client";

const SPLIT_INTO_QUIZZES = gql`
  mutation SplitIntoQuizzes($numberOfQuizzes: Int) {
    splitIntoQuizzes(numberOfQuizzes: $numberOfQuizzes) {
      ok
    }
  }
`;

const useSplitIntoQuizzesMutation = () => useMutation(SPLIT_INTO_QUIZZES);

export default useSplitIntoQuizzesMutation;
