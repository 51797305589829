import { gql, useMutation } from "@apollo/client";

const REFRESH_EVENT_TOKEN = gql`
  mutation RefreshEventToken {
    refreshEventToken {
      token
    }
  }
`;

const useRefreshEventTokenMutation = () => useMutation(REFRESH_EVENT_TOKEN);

export default useRefreshEventTokenMutation;
