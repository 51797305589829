import { useContext, useEffect, useState } from "react";
import SessionDataContext from "../../../contexts/SessionDataContext";
import EventContext from "../../../contexts/EventContext";
import { QUIZZES } from "../../../constants";
import getLogger from "../../../util/getLogger";
import useHostEventQuizMutation from "../../../hooks/mutations/useHostEventQuizMutation";

const logger = getLogger("useSendPlayerToQuizEffect");

const useSendGroupHostToQuizEffect = () => {
  const [success, setSuccess] = useState();
  const [quizName, setQuizName] = useState();
  const { myEventGroupHostId } = useContext(SessionDataContext);
  const [hostEventQuizMutation] = useHostEventQuizMutation();
  const { whatToShow } = useContext(EventContext);

  const [quizHostNotNeeded, setQuizHostNotNeeded] = useState(false);

  useEffect(() => {
    const doAction = async () => {
      logger.info("Hosting Event Quiz");
      try {
        const result = await hostEventQuizMutation();

        const data = result && result.data;
        const hostEventQuiz = data && data.hostEventQuiz;
        const quiz = hostEventQuiz && hostEventQuiz.quiz;
        const name = quiz && quiz.name;

        setQuizName(name);
        setSuccess(true);
      } catch (err) {
        logger.debug("Got an error", err);
        if (err.message === "Event Group Host not needed") {
          logger.info("not needed");
          setQuizHostNotNeeded(true);
          return;
        }
        logger.error("Error", err);
      }
    };

    if (myEventGroupHostId && whatToShow === QUIZZES) {
      doAction();
    }
  }, [myEventGroupHostId, whatToShow]);

  return {
    quizHostNotNeeded,
    success,
    quizName,
  };
};

export default useSendGroupHostToQuizEffect;
