import React, { useContext, useEffect, useState } from "react";
import EventLivePlayerContext from "../../contexts/EventLivePlayerContext";
import useCallbackRef from "../../hooks/useCallbackRef";
import VideoWithOnlyAudio from "../../components/av/VideoWithOnlyAudio";
import useAttachLivePlayerToVideo from "../../hooks/av/useAttachLivePlayerToVideo";
import getLogger from "../../../util/getLogger";

const logger = getLogger("EventAudioBroadcastController");

const EventAudioBroadcastController = () => {
  const { livePlayer, livePlayerState } = useContext(EventLivePlayerContext);
  const [videoEl, setVideoEl] = useState();
  const videoRef = useCallbackRef(setVideoEl);

  useAttachLivePlayerToVideo(livePlayer, videoEl);

  useEffect(() => {
    const { Player } = require("@twilio/live-player-sdk");

    logger.debug("got a state update", livePlayerState);
    if (livePlayerState === Player.State.Ready) {
      livePlayer.play();
      livePlayer.setVolume(0.7);
    }
  }, [livePlayerState]);

  return <VideoWithOnlyAudio videoRef={videoRef} />;
};

export default EventAudioBroadcastController;
