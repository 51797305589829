import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import CountdownButton from "../components/buttons/CountdownButton";

const HostEventQuizModal = ({ quizName, onHost }) => {
  return (
    <Modal
      title="Host Your Quiz"
      text={`You are hosting ${quizName}`}
      buttons={
        <ButtonRow>
          <CountdownButton seconds={5} onClick={onHost}>
            Host
          </CountdownButton>
        </ButtonRow>
      }
    />
  );
};

export default HostEventQuizModal;
