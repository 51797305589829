import React, { useContext, useState } from "react";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import EventHostNavigationController from "./EventHostNavigationController";
import Navigation from "../../../components/navigation/Navigation";
import SettingsController from "../../SettingsController";
import useNavigateHome from "../../../hooks/useNavigateHome";
import LeaveModal from "../../../modals/LeaveModal";
import SettingsContext from "../../../contexts/SettingsContext";

const EventNavigationController = () => {
  const { myEventHostId } = useContext(SessionDataContext);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const { initSettings } = useContext(SettingsContext);

  const navigateHome = useNavigateHome();

  const handleShowSettings = () => {
    initSettings();
    setShowSettingsModal(true);
  };

  const handleLeaveEvent = async () => {
    await navigateHome();
  };

  const modal = (
    <>
      {showSettingsModal && (
        <SettingsController
          inEvent
          onDone={() => setShowSettingsModal(false)}
        />
      )}
      {showLeaveModal && (
        <LeaveModal
          event
          onLeave={handleLeaveEvent}
          onCancel={() => setShowLeaveModal(false)}
        />
      )}
    </>
  );

  return (
    <>
      {myEventHostId && (
        <EventHostNavigationController
          onShowSettingsModal={handleShowSettings}
          onShowLeaveModal={() => setShowLeaveModal(true)}
          modal={modal}
        />
      )}
      {!myEventHostId && (
        <Navigation
          noAVControls
          onShowSettingsModal={handleShowSettings}
          onShowLeaveModal={() => setShowLeaveModal(true)}
          modal={modal}
        />
      )}
    </>
  );
};

export default EventNavigationController;
