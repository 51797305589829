import React from "react";
import { EventContextProvider } from "../contexts/EventContext";
import { SessionDataContextProvider } from "../contexts/SessionDataContext";
import { EventLoggerContextProvider } from "../contexts/EventLoggerContext";
import { NetworkQualityStatsLoggerContextProvider } from "../contexts/NetworkQualityStatsLoggerContext";
import { EventChatContextProvider } from "../contexts/EventChatContext";
import EventController from "../v2/controllers/EventController";
import { ServerTimeContextProvider } from "../contexts/ServerTimeContext";
import { SettingsContextProvider } from "../v2/contexts/SettingsContext";

export default function EventPage({ location }) {
  return (
    <ServerTimeContextProvider>
      <SessionDataContextProvider>
        <SettingsContextProvider>
          <EventContextProvider location={location}>
            <EventChatContextProvider>
              <EventLoggerContextProvider>
                <NetworkQualityStatsLoggerContextProvider>
                  <EventController />
                </NetworkQualityStatsLoggerContextProvider>
              </EventLoggerContextProvider>
            </EventChatContextProvider>
          </EventContextProvider>
        </SettingsContextProvider>
      </SessionDataContextProvider>
    </ServerTimeContextProvider>
  );
}
