import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeDangerButton from "../components/buttons/LargeDangerButton";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";

const ResetEventModal = ({ onReset, onCancel }) => {
  return (
    <Modal
      title="Reset Event"
      text="Are you sure you want to reset this event?"
      buttons={
        <ButtonRow>
          <LargeDangerButton onClick={onReset}>Reset</LargeDangerButton>
          <LargeOutlineButton noSpinner onClick={onCancel}>
            Cancel
          </LargeOutlineButton>
        </ButtonRow>
      }
      onEscapeKey={onCancel}
    />
  );
};

export default ResetEventModal;
