import { useContext, useEffect } from "react";
import EventContext from "../../contexts/EventContext";
import { navigate } from "gatsby";

const useSendEveryoneToSurveyEffect = () => {
  const { ended } = useContext(EventContext);

  useEffect(() => {
    if (ended) {
      navigate("/survey/");
    }
  }, [ended]);
};

export default useSendEveryoneToSurveyEffect;
