import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation EndEvent {
      endEvent {
        event {
          id
        }
      }
    }
  `);
