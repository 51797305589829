import React, { useState } from "react";
import { useContext } from "react";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import EventContext from "../../../../contexts/EventContext";
import EventResultsScreen, {
  LEADERBOARD,
} from "../../../screens/event/03/EventResultsScreen";
import LeaderboardTeam from "../../../components/leaderboard/LeaderboardTeam";
import Selfie from "../../../components/selfies/Selfie";
import SelfieCacheContext from "../../../../contexts/SelfieCacheContext";
import useHandleResetEvent from "../../../hooks/useHandleResetEvent";
import useEndEventMutation from "../../../hooks/mutations/useEndEventMutation";
import EventNavigationController from "../EventNavigationController";
import { VIDEO_BROADCAST } from "../../../screens/event/01/LobbyScreen";
import EventChatController from "../EventChatController";
import EventLobbyRoomController from "../EventLobbyRoomController";
import EventVideoBroadcastController from "../EventVideoBroadcastController";
import { EventLivePlayerContextProvider } from "../../../contexts/EventLivePlayerContext";

const EventResultsController = () => {
  const { myEventHostId } = useContext(SessionDataContext);
  const { name, teams, publicEventId, broadcastingVideo } = useContext(
    EventContext
  );
  const { getSelfieForParticipant } = useContext(SelfieCacheContext);
  const [endEventMutation] = useEndEventMutation();

  const [showResetEventModal, setShowResetEventModal] = useState();
  const handleResetEvent = useHandleResetEvent(publicEventId);

  const getRank = (score) =>
    teams.filter((teamToCompare) => teamToCompare.score > score).length + 1;

  return (
    <>
      <EventResultsScreen
        state={broadcastingVideo ? VIDEO_BROADCAST : LEADERBOARD}
        navigation={<EventNavigationController />}
        video={
          broadcastingVideo &&
          (myEventHostId ? (
            <EventLobbyRoomController />
          ) : (
            <EventLivePlayerContextProvider>
              <EventVideoBroadcastController />
            </EventLivePlayerContextProvider>
          ))
        }
        chat={<EventChatController />}
        eventTitle={name}
        isEventHost={myEventHostId}
        teams={
          teams &&
          teams.map((team) => (
            <LeaderboardTeam
              key={team.id}
              position={getRank(team.score)}
              emoji={team.emoji}
              teamName={team.name}
              selfies={
                team.players &&
                team.players.map((player) => (
                  <Selfie
                    key={player.id}
                    src={getSelfieForParticipant(player.id)}
                  />
                ))
              }
              score={team.score}
              showTrophies
            />
          ))
        }
        onEndEvent={() => endEventMutation()}
        onShowResetEventModal={() => setShowResetEventModal(true)}
        showResetEventModal={showResetEventModal}
        onReset={handleResetEvent}
        onCancelReset={() => setShowResetEventModal(false)}
      />
    </>
  );
};

export default EventResultsController;
